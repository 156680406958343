"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorHandler = void 0;
const DynatraceInternal_1 = require("./DynatraceInternal");
const StringUtils_1 = require("./util/StringUtils");
const _isReactNativeGlobal = (globalScope) => globalScope.ErrorUtils !== undefined;
const ErrorHandler = (Dynatrace, Logger) => ({
    registerErrorHandler() {
        if (global !== undefined && _isReactNativeGlobal(global)) {
            const oldHandler = global.ErrorUtils.getGlobalHandler();
            global.ErrorUtils.setGlobalHandler((error, isFatal) => {
                this._reportErrorToDynatrace(error, isFatal, oldHandler);
            });
            const setter = global.ErrorUtils.setGlobalHandler;
            global.ErrorUtils.setGlobalHandler = (errorHandler) => {
                setter((error, isFatal) => {
                    this._reportErrorToDynatrace(error, isFatal, errorHandler);
                });
            };
        }
    },
    _reportErrorToDynatrace: (exception, isFatal, oldHandler) => {
        if (exception != null && isExceptionAnError(exception)) {
            if (!StringUtils_1.StringUtils.isStringNullEmptyOrUndefined(exception.name)) {
                if (isFatal === undefined) {
                    isFatal = false;
                }
                if (exception.stack != null) {
                    DynatraceInternal_1.DynatraceInternal.reportErrorFromHandler(isFatal, String(exception.name), exception.message, exception.stack);
                    Logger.logDebug(`ErrorHandler _reportErrorToDynatrace(${exception}, ${isFatal})`);
                }
                else {
                    Dynatrace.reportError(String(exception.name) + ': ' + exception.message, -1);
                    Logger.logDebug(`ErrorHandler _reportErrorToDynatrace(${exception})`);
                }
            }
        }
        else {
            Dynatrace.reportError(String(exception), -1);
            Logger.logDebug(`ErrorHandler _reportErrorToDynatrace(${exception})`);
        }
        if (oldHandler !== undefined) {
            oldHandler(exception, isFatal);
        }
    },
});
exports.ErrorHandler = ErrorHandler;
const isExceptionAnError = (exception) => exception.message !== undefined;
