"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_BUNDLE_NAME_AUTO = exports.DEFAULT_BUNDLE_NAME = exports.DEFAULT_ACTION_NAME_PRIVACY = exports.DEFAULT_USER_OPT_IN = exports.DEFAULT_REPORT_CRASH = exports.DEFAULT_LOGLEVEL_AUTO = exports.DEFAULT_LOGLEVEL = exports.DEFAULT_LIFECYCLE_UPDATE_AUTO = exports.DEFAULT_LIFECYCLE_UPDATE = void 0;
const LogLevel_1 = require("../model/LogLevel");
const debugAuto = false;
const lifecycleUpdateAuto = false;
const actionNamePrivacyAuto = false;
const bundleNameAuto = undefined;
exports.DEFAULT_LIFECYCLE_UPDATE = false;
exports.DEFAULT_LIFECYCLE_UPDATE_AUTO = lifecycleUpdateAuto;
exports.DEFAULT_LOGLEVEL = LogLevel_1.LogLevel.Info;
exports.DEFAULT_LOGLEVEL_AUTO = debugAuto ? LogLevel_1.LogLevel.Debug : LogLevel_1.LogLevel.Info;
exports.DEFAULT_REPORT_CRASH = true;
exports.DEFAULT_USER_OPT_IN = false;
exports.DEFAULT_ACTION_NAME_PRIVACY = actionNamePrivacyAuto;
exports.DEFAULT_BUNDLE_NAME = undefined;
exports.DEFAULT_BUNDLE_NAME_AUTO = bundleNameAuto;
