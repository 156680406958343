"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApplicationHandler = void 0;
const ErrorHandler_1 = require("./ErrorHandler");
const Dynatrace_1 = require("./Dynatrace");
const Configuration_1 = require("./configuration/Configuration");
const Logger_1 = require("./Logger");
exports.ApplicationHandler = {
    startup: () => {
        if (!Configuration_1.Configuration.isConfigurationAvailable()) {
            Logger_1.Logger.logDebug('Configuration set: ' +
                JSON.stringify(Configuration_1.Configuration.loadConfiguration()));
            (0, ErrorHandler_1.ErrorHandler)(Dynatrace_1.Dynatrace, Logger_1.Logger).registerErrorHandler();
            Logger_1.Logger.logInfo('Dynatrace React Native Plugin started!');
        }
    },
};
