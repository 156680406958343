"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AutoStartupConfiguration = void 0;
const ConfigurationDefaults_1 = require("./ConfigurationDefaults");
class AutoStartupConfiguration {
    constructor(optionalAutoStartParameters) {
        this.lifecycleUpdate = ConfigurationDefaults_1.DEFAULT_LIFECYCLE_UPDATE_AUTO;
        this.logLevel = ConfigurationDefaults_1.DEFAULT_LOGLEVEL_AUTO;
        this.actionNamePrivacy = ConfigurationDefaults_1.DEFAULT_ACTION_NAME_PRIVACY;
        this.bundleName = ConfigurationDefaults_1.DEFAULT_BUNDLE_NAME_AUTO;
        if (optionalAutoStartParameters === undefined) {
            return;
        }
        if (optionalAutoStartParameters.lifecycleUpdate !== undefined) {
            this.lifecycleUpdate = optionalAutoStartParameters.lifecycleUpdate;
        }
        if (optionalAutoStartParameters.logLevel !== undefined) {
            this.logLevel = optionalAutoStartParameters.logLevel;
        }
        if (optionalAutoStartParameters.actionNamePrivacy !== undefined) {
            this.actionNamePrivacy = optionalAutoStartParameters.actionNamePrivacy;
        }
        if (optionalAutoStartParameters.bundleName !== undefined) {
            this.bundleName = optionalAutoStartParameters.bundleName;
        }
    }
}
exports.AutoStartupConfiguration = AutoStartupConfiguration;
