"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Logger = void 0;
const Configuration_1 = require("./configuration/Configuration");
const LOG_PREFIX = 'DYNATRACE: ';
exports.Logger = {
    logDebug: (message) => {
        if (Configuration_1.Configuration.isConfigurationAvailable() && Configuration_1.Configuration.isDebugEnabled()) {
            console.log(LOG_PREFIX + message);
        }
    },
    logInfo: (message) => {
        console.log(LOG_PREFIX + message);
    },
};
