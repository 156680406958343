"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Configuration = void 0;
const LogLevel_1 = require("../model/LogLevel");
const AutoStartupConfiguration_1 = require("./AutoStartupConfiguration");
let mainConfig;
exports.Configuration = {
    setConfiguration(configuration) {
        this.loadConfiguration();
        mainConfig = new AutoStartupConfiguration_1.AutoStartupConfiguration(configuration);
    },
    loadConfiguration: () => {
        mainConfig = new AutoStartupConfiguration_1.AutoStartupConfiguration();
        return mainConfig;
    },
    isConfigurationAvailable: () => mainConfig !== undefined,
    isDebugEnabled: () => mainConfig.logLevel === LogLevel_1.LogLevel.Debug,
    isLifecycleUpdateEnabled: () => mainConfig.lifecycleUpdate,
    isActionNamePrivacyEnabled: () => mainConfig.actionNamePrivacy,
    getBundleName: () => mainConfig.bundleName,
};
