"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PickerHelper = void 0;
const PickerHelper = (Dynatrace, Logger) => ({
    attachToOnValueChange(onValueChange, children) {
        return (itemValue, itemNumber) => {
            const value = this.getPickerItemValue(children, itemNumber, itemValue);
            const action = Dynatrace.enterAutoAction(`Picked Value: ${value}`);
            if (onValueChange !== undefined) {
                onValueChange(itemValue, itemNumber);
            }
            if (action != null) {
                action.leaveAction();
            }
        };
    },
    getPickerItemValue: (children, index, value) => {
        if (children != null && index >= 0) {
            if (children != null &&
                children.length >= index &&
                isPickerItemProps(children[index].props)) {
                return children[index].props.label;
            }
        }
        return value;
    },
});
exports.PickerHelper = PickerHelper;
const isPickerItemProps = (props) => props.label !== undefined;
