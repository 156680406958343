"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TouchableHelper = void 0;
const Configuration_1 = require("./configuration/Configuration");
const TouchableHelper = (Dynatrace, Logger) => ({
    attachOnPress(longPress, props, children) {
        const origFunction = longPress && this._isLongPress(props)
            ? props.onLongPress
            : props.onPress;
        if (!Configuration_1.Configuration.isConfigurationAvailable()) {
            Logger.logInfo('TouchableHelper: React Native plugin has not been started yet! Touch will not be reported!');
            return origFunction;
        }
        const nameOfAction = this._findActionName(props, children);
        return (event) => {
            if (nameOfAction == null) {
                Logger.logDebug('TouchableHelper: Skipping creation of action as no name was found!');
                if (origFunction != null) {
                    origFunction(event);
                }
            }
            else {
                const action = Dynatrace.enterAutoAction(`Touch on ${nameOfAction}`);
                if (action != null) {
                    if (origFunction != null) {
                        origFunction(event);
                    }
                    action.leaveAction();
                }
            }
        };
    },
    _findActionName(props, children) {
        if (this._isDynatraceProperties(props)) {
            return props.dtActionName;
        }
        else if (Configuration_1.Configuration.isActionNamePrivacyEnabled()) {
            return this._isPropsButton(props) ? 'Button' : 'Touchable';
        }
        else if (this._isPropsButton(props) && props.title != null) {
            return props.title;
        }
        else if (props.accessibilityLabel != null) {
            return props.accessibilityLabel;
        }
        else if (children != null &&
            children.length === 1 &&
            typeof children[0] === 'string') {
            return children[0];
        }
        else {
            return this._walkChildrenToFindText(children);
        }
    },
    _isPropsButton: (props) => props.title != null,
    _isImageButton: (props) => props.source != null,
    _isLongPress: (props) => props.onLongPress != null,
    _isImageURISourceType: (source) => source.uri != null,
    _isIconButton: (element) => {
        const type = element.type;
        return type != null && type.name === 'Icon';
    },
    _walkTreeToFindText(element) {
        if (element == null || element.props == null) {
            return null;
        }
        else if (this._isImageButton(element.props)) {
            if (this._isImageURISourceType(element.props.source)) {
                return 'Image Button: ' + element.props.source.uri;
            }
            else {
                return 'Image Button';
            }
        }
        else if (this._isIconButton(element) && element.props.name != null) {
            return 'Icon Button: ' + element.props.name;
        }
        return this._walkChildrenToFindText(element.props.children);
    },
    _walkChildrenToFindText(children) {
        if (typeof children === 'string') {
            return children;
        }
        else if (Array.isArray(children)) {
            for (const child of children) {
                if (this._isReactElement(child)) {
                    const name = this._walkTreeToFindText(child);
                    if (name != null) {
                        return name;
                    }
                }
            }
            return null;
        }
        else {
            return this._walkTreeToFindText(children);
        }
    },
    _isReactElement: (node) => node != null && node.props != null,
    _isDynatraceProperties: (properties) => properties.dtActionName !== undefined,
});
exports.TouchableHelper = TouchableHelper;
